var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    d-flex\n    align-items-center\n    justify-content-center\n    position-relative\n    border\n    rounded\n    p-2\n  "},[(
      (_vm.file &&
        _vm.imgSrc &&
        (_vm.imgSrc.slice(-3).toLowerCase() === 'jpg' ||
          _vm.imgSrc.slice(-4).toLowerCase() === 'jpeg' ||
          _vm.imgSrc.slice(-3).toLowerCase() === 'png' ||
          _vm.imgSrc.slice(-4).toLowerCase() === 'jfif' ||
          _vm.imgSrc.substring(0, 5).toLowerCase() === 'data:')) ||
      (_vm.file.type && _vm.file.type.substring(0, 5).toLowerCase() === 'image')
    )?_c('img',{staticClass:"file w-100 rounded",class:'object-fit-' + _vm.objectFit,attrs:{"src":_vm.imgSrc,"alt":"","height":"100px"}}):_c('span',{staticClass:"file d-flex flex-column justify-content-center align-items-center"},[_c('feather-icon',{staticClass:"p-1",attrs:{"icon":"FileIcon","size":"100"}}),_c('p',{staticClass:"text-center text-wrap"},[_vm._v(" "+_vm._s(_vm.customFileName || _vm.file.name || "File")+" ")])],1),(_vm.file && _vm.removable)?_c('feather-icon',{staticClass:"close_icon cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.$emit('remove')}}}):_vm._e(),(_vm.file && _vm.showActions)?_c('section',{staticClass:"overlay"},[_c('a',{attrs:{"href":_vm.imgSrc,"target":"_blank","download":_vm.customFileName || _vm.file.name || 'File'}},[(_vm.file)?_c('feather-icon',{staticClass:"cursor-pointer text-primary",attrs:{"icon":"EyeIcon","size":"20"}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.$t("preview_file"))+" ")])],1),_c('a',{attrs:{"href":_vm.imgSrc,"target":"_blank","download":_vm.customFileName || _vm.file.name || 'File'}},[_c('feather-icon',{staticClass:"cursor-pointer text-primary",attrs:{"icon":"DownloadIcon","size":"20"}}),_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.$t("download_file"))+" ")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }