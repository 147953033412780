<template>
  <div
    class="
      d-flex
      align-items-center
      justify-content-center
      position-relative
      border
      rounded
      p-2
    "
  >
    <img
      v-if="
        (file &&
          imgSrc &&
          (imgSrc.slice(-3).toLowerCase() === 'jpg' ||
            imgSrc.slice(-4).toLowerCase() === 'jpeg' ||
            imgSrc.slice(-3).toLowerCase() === 'png' ||
            imgSrc.slice(-4).toLowerCase() === 'jfif' ||
            imgSrc.substring(0, 5).toLowerCase() === 'data:')) ||
        (file.type && file.type.substring(0, 5).toLowerCase() === 'image')
      "
      :src="imgSrc"
      alt=""
      class="file w-100 rounded"
      :class="'object-fit-' + objectFit"
      height="100px"
    />
    <span
      v-else
      class="file d-flex flex-column justify-content-center align-items-center"
    >
      <feather-icon class="p-1" icon="FileIcon" size="100" />
      <p class="text-center text-wrap">
        {{ customFileName || file.name || "File" }}
      </p>
    </span>
    <feather-icon
      v-if="file && removable"
      class="close_icon cursor-pointer"
      icon="XIcon"
      size="16"
      @click="$emit('remove')"
    />
    <section v-if="file && showActions" class="overlay">
      <a
        :href="imgSrc"
        target="_blank"
        :download="customFileName || file.name || 'File'"
      >
        <feather-icon
          v-if="file"
          class="cursor-pointer text-primary"
          icon="EyeIcon"
          size="20"
        />
        <span class="sr-only">
          {{ $t("preview_file") }}
        </span>
      </a>
      <a
        :href="imgSrc"
        target="_blank"
        :download="customFileName || file.name || 'File'"
      >
        <feather-icon
          class="cursor-pointer text-primary"
          icon="DownloadIcon"
          size="20"
        />
        <span class="sr-only">
          {{ $t("download_file") }}
        </span>
      </a>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: [String, File],
      required: false,
      default: null,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    objectFit: {
      type: String,
      default: "cover",
    },
    customFileName: {
      type: String,
      default: null,
    },
  },
  computed: {
    returnIfPath() {
      return !!(this.file && typeof this.file !== "string");
    },
    imgSrc() {
      if (!this.file) return;
      if (this.returnIfPath) return this.file;
      return this.generateFiles(this.file);
    },
  },
  methods: {
    previewHandler() {
      window.open(this.imgSrc);
      this.$emit("preview");
    },
    generateFiles(file) {
      if (!file) return;
      if (typeof file === "string") return file;
      return URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-wrap {
  word-break: break-all;
}

.close_icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  svg {
    margin: 5px;
  }
}
.file:hover + .overlay,
.overlay:hover {
  visibility: visible;
}
</style>
