<template>
  <div>
    <!-- <ItemForm
      :id="`crud-form`"
      :document="Document"
      @hide="hideItemForm"
      @reset="hideItemForm"
    /> -->

    <!-- TODO : add Collapse  -->
    <Filters @filterDocs="fetchDocsHandler" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Search -->
      <div
        class="
          p-2
          d-flex
          align-items-center
          justify-content-md-between
          flex-column-reverse flex-md-row
        "
      >
        <div>
          <transition name="fade">
            <span v-if="selectedRows && selectedRows.length" class="d-flex">
              <AppButton
                variant="primary"
                class="mr-2"
                data-test="unselect-all-btn"
                @click="unselectAllRowsHandler"
              >
                <span class="text-nowrap">
                  {{ $t("unselect_all") || "Unselect all" }}
                </span>
              </AppButton>
              <AppButton
                variant="danger"
                data-test="delete-selected-btn"
                @click="deletionWarningHandler(selectedRows)"
              >
                <span class="text-nowrap">
                  {{ $t("delete_all_selected") || "Delete All Selected" }}
                </span>
              </AppButton>
            </span>
          </transition>
        </div>
        <div class="d-flex flex-column flex-md-row mb-2 mb-md-0">
          <AppInput
            v-model="searchQuery"
            data-test="search-input"
            class="search-input mb-2 mb-md-0 d-inline-block w-auto"
            :placeholder="$t('search_by_name') || 'Search by name'"
            autocomplete="off"
            group-classes="mb-0"
            @input="searchDocsHandler"
          />
          <b-link
            v-if="userCan('post-add')"
            data-test="add-new-btn"
            class="btn btn-primary mx-1 mb-2 mb-md-0"
            to="/add-new-page"
          >
            <span class="text-nowrap">
              {{ $t("add_new_page") || "Add New Page" }}
            </span>
          </b-link>
        </div>
      </div>
      <b-overlay :show="isLoading || isDeleting" rounded>
        <b-table
          ref="table"
          class="position-relative"
          :items="dataArray"
          responsive
          hover
          striped
          :fields="fields"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
        >
          <template #cell(checkbox)="data">
            <span class="align-text-top text-capitalize">
              <!-- data.item.can_edit_or_delete == '1' -->
              <b-form-checkbox
                :value="data.item.uuid"
                :checked="
                  selectedRows &&
                  selectedRows.length &&
                  selectedRows.find((e) => e === data.item.uuid)
                "
                class="align-text-top"
                @change="rowSelectedHandler($event, data.item.uuid)"
              ></b-form-checkbox>
            </span>
          </template>
          <template #cell(image)="data">
            <img
              v-if="data.item.image"
              data-test="image"
              height="100"
              width="100"
              class="object-fit-contain mb-2"
              :src="data.item.image.path || data.item.image"
              :alt="data.item.image.alt_ar || ''"
            />
          </template>
          <!-- <template #cell(document)="data">
            <span class="align-text-top text-capitalize">
              <ImagePreviewer
                v-if="data.item.image"
                :file="data.item.image"
                :show-actions="true"
              />
            </span>
          </template> -->
          <template #cell(title)="data">
            <span class="align-text-top text-capitalize">
              {{ data.item.title }}
            </span>
          </template>
          <template #cell(created_at)="data">
            <span
              v-if="data.item.created_at"
              class="align-text-top text-capitalize"
            >
              {{ formatDate(data.item.created_at) }}
            </span>
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                v-if="userCan('post-edit')"
                variant="primary"
                class="mb-1"
                @click="goToEditHandler(data.item.uuid)"
              >
                {{ $t("edit") || "Edit" }}
              </AppButton>
              <AppButton
                v-if="userCan('post-delete')"
                variant="danger"
                @click="deletionWarningHandler(data.item)"
              >
                {{ $t("delete") || "Delete" }}
              </AppButton>
            </div>
          </template>
        </b-table>

        <!-- pagination -->
        <div v-if="dataArray && dataArrayMeta.total" class="m-2">
          <b-row>
            <b-col
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span v-if="dataArrayMeta" class="text-muted">
                {{ $t("total_entries", { total: dataArrayMeta.total }) || "" }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-if="dataArrayMeta && dataArrayMeta.total"
                v-model="dataArrayMeta.current_page"
                :total-rows="dataArrayMeta.total"
                :per-page="dataArrayMeta.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePaginationHandler"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    <Warning
      id="delete-warning-modal"
      :is-loading="isDeleting"
      :title="$t('delete_selected') || 'Delete Selected'"
      :payload="itemsToBeDeleted"
      @cancel="cancelDeletionHandler"
      @approve="deleteHandler"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BLink,
} from "bootstrap-vue";
import { AppButton, AppInput } from "@/components/form/index";
// import ItemForm from "./partials/PageForm.vue";
import Filters from "./partials/filters.vue";
import ImagePreviewer from "@/components/UI/ImagePreviewer.vue";
import userCan from "@/mixins/UserCan";

export default {
  name: "pages",
  components: {
    BRow,
    BCol,
    BLink,
    BFormCheckbox,
    // ItemForm,
    BCard,
    BTable,
    BOverlay,
    AppButton,
    AppInput,
    BPagination,
    Filters,
    ImagePreviewer,
    BAvatar,
    BAvatarGroup,
    BBadge,
  },
  data() {
    return {
      Document: null,
      fields: [
        {
          key: "checkbox",
          label: this.$t("select") || "Select",
        },
        { key: "edit_delete", label: this.$t("edit_delete") || "Edit/Delete" },
        {
          key: "image",
          label: this.$t("image") || "image",
        },
        {
          key: "title_ar",
          label: this.$t("title_ar") || "Title in Arabic",
        },
        {
          key: "slug_ar",
          label: this.$t("slug_ar") || "slug_ar",
        },
      ],
      appliedFilters: {},
      searchQuery: "",
      searchQueryTimer: null,
      selectedRows: [],
      itemPreviewed: null,
      itemsToBeDeleted: [],
    };
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    dataArray() {
      return this.$store.state.pages.data;
    },
    dataArrayMeta() {
      return this.$store.state.pages.meta;
    },
    isLoading() {
      return this.$store.state.pages.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.pages.isLoading.deleting;
    },
  },
  created() {
    this.fetchDocsHandler();
  },
  mounted() {},
  methods: {
    userCan,
    goToEditHandler(uuid) {
      this.$router.push({
        name: "edit-page",
        query: { uuid },
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    previewInModal(item) {
      this.itemPreviewed = item;
      this.$bvModal.show("table-preview-modal");
    },
    cancelDeletionHandler() {
      this.$bvModal.hide("delete-warning-modal");
    },
    deleteHandler(id, payload) {
      this.$bvModal.hide(id);
      payload && payload.length
        ? this.deleteAllSelectedHandler(payload)
        : this.deleteAction({ uuid: payload.uuid });
    },
    deleteAction(payload) {
      this.$store.dispatch("pages/deleteData", payload);
    },
    deletionWarningHandler(items) {
      if (!items) return;
      this.itemsToBeDeleted = items;
      this.$bvModal.show("delete-warning-modal");
    },
    deleteAllSelectedHandler() {
      if (!this.selectedRows || !this.selectedRows.length) return;
      this.$store
        .dispatch("pages/deleteAllSelectedRows", {
          pages: this.selectedRows,
        })
        .then(() => {
          this.selectedRows = [];
        });
    },
    unselectAllRowsHandler() {
      this.selectedRows = [];
    },
    rowSelectedHandler(state, uuid) {
      if (
        this.selectedRows &&
        this.selectedRows.length &&
        this.selectedRows.find((e) => e === uuid)
      ) {
        this.selectedRows = this.selectedRows.filter((e) => e !== uuid);
      } else {
        this.selectedRows.push(uuid);
      }
    },
    changePaginationHandler(page = 1) {
      this.fetchDocsHandler({ page });
    },
    searchDocsHandler(searchText) {
      // debounce
      // TODO : make a mixin for search
      clearTimeout(this.searchQueryTimer);
      this.searchQueryTimer = setTimeout(() => {
        const searchQuery = { name: searchText };
        this.fetchDocsHandler(searchQuery);
      }, 300);
    },
    openItemForm(e, item = null) {
      if (item) this.singleItem = item;
      if (
        item &&
        Object.keys(item).includes("can_edit_or_delete") &&
        item.can_edit_or_delete == 0
      )
        return;

      this.$bvModal.show("crud-form");
    },
    hideItemForm() {
      this.singleItem = null;
      this.$store.dispatch("pages/resetModule");
    },

    fetchDocsHandler(params = null) {
      this.appliedFilters = {
        ...this.appliedFilters,
        page: 1, // reset page to 1 unless page param is passed underneath
        ...params,
      };
      this.$store.dispatch(
        "pages/fetchData",
        JSON.parse(JSON.stringify(this.appliedFilters))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
